<template>
    <div>
        <p class="body-l-semibold">Редактирование цветов</p>
        <color-picker v-model="colors.backgroundColor">
            Цвет фона
        </color-picker>
        <color-picker v-if="walletType === 1" v-model="colors.labelColor">
            Цвет заголовков
        </color-picker>
       <color-picker v-if="walletType === 1" v-model="colors.foregroundColor">
            Цвет шрифта 
        </color-picker>
        
    </div>
</template>

<script>
import colorPicker from './colorPicker.vue';
export default {
    props : {
        defaultBackground : String,
        defaultForeground : String,
        defaultLabelColor : String,
        walletType: {type: Number, default: 0}
    },
    components : {colorPicker},
    data(){
        return{
            colors: {
                backgroundColor : this.defaultBackground,
                foregroundColor : this.defaultForeground,
                labelColor : this.defaultLabelColor
            }
        }
    },
    watch:{
        colors:{
            deep : true,
            handler(v){
                this.$emit('updateColors', v)
            }
        },
        defaultBackground(v){
            this.colors.backgroundColor = v
        },
        defaultLabelColor(v){
            this.colors.labelColor = v
        },
        defaultForeground(v){
            this.colors.foregroundColor = v
        }
    },
    computed:{
        segments () {
          return this.$store.getters['crm/segment/segments'] 
      },
    }
}
</script>

<style>

</style>