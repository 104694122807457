<template>
  <div>
    <p class="body-l-semibold">Изображения</p>
    <div>
        <div class="body-m-semibold mb-1 mt-1">
            Логотип
            <v-btn
                text
                color="primary"
                @click="$refs.downloadLogo.click()"
            >
                <v-icon>
                    $iconify_tray-arrow-up
                </v-icon>
            </v-btn>
        </div>
        <v-img 
            :src="logoImg.data ? logoImg.data : this.defaultImgs[0] + '?' + Date.now()" 
            :width="30" 
            :height="30"
            style="border-radius: 50%;"
        />
    </div>
    <div>
        <div class="body-m-semibold mb-1 mt-1">
            Дополнительное
            <v-btn
                text
                color="primary"
                @click="$refs.downloadStripImg.click()"
            >
                <v-icon>
                    $iconify_tray-arrow-up
                </v-icon>
            </v-btn>
        </div>
        <v-img 
            :src="stripImg.data ? stripImg.data : this.defaultImgs[1] + '?' + Date.now()" 
            :width="330"
            :height="110"
        />
    </div>
    <div>
        <div class="body-m-semibold mb-1 mt-1">
            Основное
            <v-btn
                text
                color="primary"
                @click="$refs.downloadPush.click()"
            >
                <v-icon>
                    $iconify_tray-arrow-up
                </v-icon>
            </v-btn>
        </div>
        <v-img 
            :src="pushImg.data ? pushImg.data : this.defaultImgs[2] + '?' + Date.now()" 
            :width="330"
            :height="110"
        />
    </div>
    <input
        ref="downloadStripImg"
        type="file"
        class="sr-only"
        name="file"
        accept="image/*"
        hidden
        @change="downloadImg($event, 'strip')"
    >
    <input
        ref="downloadLogo"
        type="file"
        class="sr-only"
        name="file"
        accept="image/*"
        hidden
        @change="downloadImg($event, 'logo')"
    >
    <input
        ref="downloadPush"
        type="file"
        class="sr-only"
        name="file"
        accept="image/*"
        hidden
        @change="downloadImg($event, 'push')"
    >
    <image-cropper
        v-if="cropperDialogLogo"
        :p-dialog.sync="cropperDialogLogo"
        :p-title="'Загрузка изображения'"
        :p-img.sync="logoImg"
        :p-selected.sync="selectedImg"
        :p-aspect-ratio="1"
        :p-circle="true"
        :maxImageSize="409600"
    />
    <image-cropper
        v-if="cropperDialogImg"
        :p-dialog.sync="cropperDialogImg"
        :p-title="'Загрузка изображения'"
        :p-img.sync="stripImg"
        :p-selected.sync="selectedImg"
        :p-aspect-ratio="3"
        :p-circle="false"
        :maxImageSize="409600"
    />
    <image-cropper
        v-if="cropperDialogPush"
        :p-dialog.sync="cropperDialogPush"
        :p-title="'Загрузка изображения'"
        :p-img.sync="pushImg"
        :p-selected.sync="selectedImg"
        :p-aspect-ratio="3"
        :p-circle="false"
        :maxImageSize="409600"
    />
  </div>
</template>

<script>
import ImageCropper from '@/components/dialogs/ImageCropper'
export default {
    components:{
        ImageCropper
    },
    props : {defaultImages : Array},
    data(){
        return {
            tab : 0,
            cropperDialogImg : false,
            cropperDialogLogo: false,
            cropperDialogPush : false,
            defaultImgs : [this.defaultImages[0], this.defaultImages[1], this.defaultImages[2]],
            logoImg: {
                data: null,
                mime: null,
                blob : null,
            },
            stripImg: {
                data: null,
                mime: null,
                blob : null,
            },
            pushImg: {
                data: null,
                mime: null,
                blob : null,
            },
            selectedImg: null,
        }
    },
    watch:{
        logoImg(v){
            this.$emit('updateImages', [{
                logo : this.logoImg.blob ? this.logoImg.blob : this.logoImg.data ? this.logoImg.data : this.defaultImgs[0],
                hero : this.stripImg.blob ? this.stripImg.blob : this.stripImg.data ? this.stripImg.data : this.defaultImgs[1],
                push : this.pushImg.blob ? this.pushImg.blob : this.pushImg.data ? this.pushImg.data : this.defaultImgs[2]
            }, {
                logo : this.logoImg.data ? this.logoImg.data : this.defaultImgs[0],
                hero : this.stripImg.data ? this.stripImg.data : this.defaultImgs[1],
                push : this.pushImg.data ? this.pushImg.data : this.defaultImgs[2],

            }])
        },
        stripImg(v){
            this.$emit('updateImages', [{
                logo : this.logoImg.blob ? this.logoImg.blob : this.logoImg.data ? this.logoImg.data : this.defaultImgs[0],
                hero : this.stripImg.blob ? this.stripImg.blob : this.stripImg.data ? this.stripImg.data : this.defaultImgs[1],
                push : this.pushImg.blob ? this.pushImg.blob : this.pushImg.data ? this.pushImg.data : this.defaultImgs[2]
            }, {
                logo : this.logoImg.data ? this.logoImg.data : this.defaultImgs[0],
                hero : this.stripImg.data ? this.stripImg.data : this.defaultImgs[1],
                push : this.pushImg.data ? this.pushImg.data : this.defaultImgs[2],

            }])
        },
        pushImg(v){
            this.$emit('updateImages', [{
                logo : this.logoImg.blob ? this.logoImg.blob : this.logoImg.data ? this.logoImg.data : this.defaultImgs[0],
                hero : this.stripImg.blob ? this.stripImg.blob : this.stripImg.data ? this.stripImg.data : this.defaultImgs[1],
                push : this.pushImg.blob ? this.pushImg.blob : this.pushImg.data ? this.pushImg.data : this.defaultImgs[2]
            }, {
                logo : this.logoImg.data ? this.logoImg.data : this.defaultImgs[0],
                hero : this.stripImg.data ? this.stripImg.data : this.defaultImgs[1],
                push : this.pushImg.data ? this.pushImg.data : this.defaultImgs[2],

            }])
        }
    },
    methods:{
        downloadImg(e, caller){
            console.log("DOWNLOAD EVENT", e)
            this.selectedImg = e.target.files[0]
            if (caller === 'logo')
                this.cropperDialogLogo = true
            if (caller === 'strip')
                this.cropperDialogImg = true
            if (caller === 'push')
                this.cropperDialogPush = true
            e.target.type = 'text'
            e.target.type = 'file'
        },
    },
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default{
    min-width: 0;
    margin-left: 5px;
}
</style>