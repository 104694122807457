<template>
    <v-col >
        <v-tabs
            v-model="tab"
            color="primary"
            class="d-flex justify-center flex-row mb-2"
        >
            <v-tab @click="$emit('change', 0)">Google Wallet</v-tab>
            <v-tab @click="$emit('change', 1)">Apple Wallet</v-tab>
        </v-tabs>
        <!--GOOGLE Wallet card-->
        <div class="d-flex flex-row" v-if="tab === 0">
            <div class="card" :style="'background-color: ' + fields.hexBackgroundColor + ';'">
                <div>
                <div class="card-header">
                    <div class="logo">
                        <v-img
                            :src="images[0].indexOf('http') > -1 ? images[0] + '?'+ nowDate : images[0]"
                            :width="30"
                            :height="30"
                        />
                    </div>
                    <div class="issuerName body-s-semibold">
                        {{ fields.programName }}
                    </div>
                </div>
                <div class="card-content">
                        <div class="programName body-l-semibold">
                            {{ program.name }}
                        </div>
                        <div class="points">
                            <span class="body-s-regular">Баллы</span>
                            <span class="body-s-semibold">100</span>
                        </div>
                        <div class="qr">
                            <v-img 
                                v-if="fields.codeType === 'CODE_128'" 
                                src="https://roskod.ru/wp-content/uploads/2017/10/maxresdefault-1024x576.jpg"
                                :width="200" 
                            />
                            <v-img 
                                v-if="fields.codeType === 'QR_CODE'" 
                                src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                                :width="150" 
                                style="background-color: #FFF;"
                            />

                        </div>
                </div>
            </div>
            <div class="heroImg">
                <v-img
                    :src="images[1].indexOf('http') > -1 ? images[1] + '?'+ nowDate : images[1]"
                    :width="330"
                    :height="110"
                />
            </div>
            </div>

            <div class="card backside">
                <div class="card-header">
                    <div class="logo">
                        <v-img
                            :src="images[0].indexOf('http') > -1 ? images[0] + '?'+ nowDate : images[0]"
                            :width="30"
                            :height="30"
                        />
                    </div>
                </div>
                <div class="card-content">
                    <div class="programName body-l-semibold">
                        {{ fields.programName }}
                    </div>
                    <div class="body-m-semibold"> 
                        Сведения
                    </div>
                    <div class="mainImg">
                        <v-img
                            :src="images[2].indexOf('http') > -1 ? images[2] + '?'+ nowDate : images[2]"
                            :width="330"
                            :height="110"
                        />
                    </div>
                    <div class="textHeader body-m-semibold">
                        <div>{{ fields.textHeader }}</div>
                    </div>
                    <div class="textBody body-s-semibold">
                        <div>{{ fields.textBody }}</div>
                    </div>
                    <div class="phone body-s-regular">
                        <v-icon>$iconify_feather-phone</v-icon> {{ fields.merchantTel }} 
                    </div>
                    <div class="cite body-s-regular">
                        <v-icon>$iconify_mdi-web</v-icon> {{ fields.merchantUrl ? fields.merchantUrl : 'Сайт компании' }} 
                    </div>
                </div>
            </div>
        </div>
        <!--APPLE Wallet card-->
        <div class="d-flex flex-row" v-if="tab === 1">
            <div class="card apple-card" :style="'background-color: ' + fields.hexBackgroundColor + ';'">
                <div class="card-header apple-header">
                    <div class="d-flex flex-row align-center logoText">
                        <div class="logo">
                            <v-img
                                :src="images[0].indexOf('http') > -1 ? images[0] + '?'+ nowDate : images[0]"
                                :width="50"
                                :height="50"
                            >

                            </v-img>    
                        </div>
                        <div class="body-l-semibold headerProgramName" :style="'color:' + fields.hexFontColor+';'">
                            {{ fields.programName }}
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-end balance">
                        <div :style="'color:' + fields.hexLabelColor+';'">
                            БАЛАНС
                        </div>
                        <div class="body-l-semibold" :style="'color:' + fields.hexFontColor+';'">
                            100
                        </div>
                    </div>
                    
                </div>

                <div class="appleHero">
                    <v-img
                        :src="images[1].indexOf('http') > -1 ? images[1] + '?'+ nowDate : images[1]"
                        :width="330"
                        :height="110"
                    >
                        <div v-if="!fields.hideProgramName" class="programName">
                            {{ program.name }}
                        </div>
                    </v-img>
                </div>

                <div class="holderName">
                    <div>
                        <div class="body-s-regular mt-1" :style="'color:' + fields.hexLabelColor+';' ">ВЛАДЕЛЕЦ</div>
                        <div class="title-m-regular" :style="'color:' + fields.hexFontColor+';'">Имя Владельца</div>
                    </div>
                    
                    <div class="qr">
                        <v-img 
                            v-if="fields.codeType === 'CODE_128'" 
                            src="https://roskod.ru/wp-content/uploads/2017/10/maxresdefault-1024x576.jpg"
                            :width="200" 
                        />
                        <v-img 
                            v-if="fields.codeType === 'QR_CODE'" 
                            src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                            :width="150" 
                            style="background-color: #FFF;"
                        />
                    </div>
                </div>

                
            </div>
            <div class="card apple-backside">
                <div class="mini" :style="'background-color: ' + fields.hexBackgroundColor + ';'">
                    <div class="d-flex flex-row justify-space-between">
                        <div class="logo d-flex flex-row align-center">
                            <v-img
                                :src="images[0].indexOf('http') > -1 ? images[0] + '?'+ nowDate : images[0]"
                                :width="15"
                                :height="15"
                            >

                            </v-img>
                            <div class="program-name" :style="'color:' + fields.hexFontColor+';'">
                                {{ fields.programName }}
                            </div>
                        </div>
                        <div class="balance d-flex flex-column justify-start">
                            <div class="body-xs-regular" :style="'color:' + fields.hexLabelColor+';'">БАЛАНС</div>
                            <div class="body-s-semibold" :style="'color:' + fields.hexFontColor+';'">100</div>
                        </div>
                    </div>
                    <div>
                        <v-img 
                            :src="images[1].indexOf('http') > -1 ? images[1] + '?'+ nowDate : images[1]"
                            :width="330 / 3"
                            :height="110 / 3"
                            
                        >
                            <div class="body-xs-regular programName" v-if="!fields.hideProgramName">
                                {{ program.name }}
                            </div>
                        </v-img>
                    </div>
                    <div class="body-xs-regular owner" :style="'color:' + fields.hexLabelColor+';'">
                        ВЛАДЕЛЕЦ
                    </div>
                </div>
                <div class="textHeader title-s-semibold">
                    {{ fields.textHeader }}
                </div>
                <div class="removeCard body-l-regular">Удалить карту</div>
                <div class="information body-l-regular">
                    <div class="body-m-regular">
                        Владелец
                    </div>
                    <div class="line">
                        Имя Владельца
                    </div>
                    <div class="body-m-regular">
                        Телефон
                    </div>
                    <div class="line">
                        +{{ fields.merchantTel }}
                    </div>
                    <div class="body-m-regular">
                        Дата рождения
                    </div>
                    <div class="line">
                        xx.xx.xxxx
                    </div>
                    <div class="body-m-regular">
                        {{fields.textBody}}
                    </div>
                </div>
            </div>
        </div>
        
    </v-col>
</template>

<script>
export default {
    props:{
        fields : Object,
        images: Array
    },
    data(){
        return {
            tab : 0,
            nowDate : Date.now()
        }
    },
    computed:{
        merchant(){
            return this.$store.getters['settings/organization/merchant']
        },
        program () {
            return this.$store.getters['company/program/program']
        },
    },
    watch: {
       tab(v){
            this.$emit('change', v)
       }
    },
    created(){
       
    },
    methods:{
       
    },
}
</script>

<style scoped lang="scss">
.card{
    width: 330px;
    min-height: 500px;
    box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-wrap: anywhere;
    .card-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        .logo{
            border-radius: 50%;
            .v-image{
                border-radius: 50%;
            }
        }
        .issuerName{
            margin-left: 1rem;
        }
    }

    .card-content{
        
        display: flex;
        flex-direction: column;
        &>*{
            padding-left: 16px;
            padding-right: 16px;
        }

        .points{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }
        .qr{
            margin: 0 auto;
            margin-top: 1rem;
            max-width: 70%;

            .v-image{
                border-radius: 10px;
            }
        }
    }

    .heroImg{
        .v-image{
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        
    }

    
}

.apple-card{
    justify-content: flex-start;
    .apple-header{
        padding: 4px 10px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        
        .logoText{
            max-width: 70%;
        }
        
        .logo{
            margin-right: 4px;

        }

        .headerProgramName{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .balance{
            text-align: right;
            align-items: flex-end;
            width: 29%;

            div:first-child{
                align-self: flex-end;
            }
        }
        
    }

    .appleHero{
        .programName{
            font-size: 30px;
            padding: 4px 10px;
            color: white;
        }
    }

    .holderName{
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 300px;
        .qr{
            align-self: center;
            justify-self: flex-end;
        }
    }

    
}

.backside{
    margin-left : 2rem;
    justify-content: flex-start;
    .card-content{
        
        .programName{
            margin-bottom: 10px;
        }
        .mainImg{
            margin-top: 10px;
            margin-bottom: 16px;
            .v-image{
                border-radius: 5px;
            }
        }
        .textHeader{
            height: auto;
        }
        .textBody{
            height: auto;
            margin-bottom: 16px;   
        }

        .phone{
            margin-bottom: 1rem;
        }
    }
}

.apple-backside{
    margin-left : 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    .mini{
        margin: 0 auto;
        margin-top: 16px;
        width: 110px;
        height: 90px;
        border-radius: 4px;

        .logo{
            border-radius: 50%;
            margin-right: 5px;
            max-width: 58px;
            padding: 0px 4px;

            .v-image{
                border-radius: 50%;
            }

            .program-name{
                font-size: 7px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .balance{
            margin-bottom: 3px;
            padding-right: 4px;
            text-align: right;
            align-self: flex-end;
            .body-xs-regular{
                font-size: 5px;
                line-height: 10px;
            }
            .body-s-semibold{
                font-size: 7px;
                line-height: 12px;
            }

        }

        .programName{
            padding: 0px 4px;
            color:  white;
        }
        .owner{
            padding: 3px 4px;
            font-size: 7px;
        }
        
    }
    .textHeader{
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        color: white;
    }

    .removeCard{
        width: 90%;
        margin-bottom: 2rem;
        padding: 12px;
        color: rgb(197,75,87);
        background-color: rgb(77, 77, 83);
        border-radius: 8px;
    }

    .information{
        width: 90%;
        padding: 8px 12px;
        text-align: left;
        background-color: rgb(77, 77, 83);
        border-radius: 8px;
        color: lightgrey;
        .line{
            border-bottom: 1px solid grey;
            padding-bottom: 8px;
            padding-top: 2px;
        }
    }

}

.v-tabs{
    max-height: 50px;
}



</style>