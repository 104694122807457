<template>
    <div>
        <top-menu
            :menu="menu"
            :value="3"
            cancel-button-text="Отменить"
            :loading="loading"
            :showAction="false"
            @cancelbutton="cancel()"
        />
        <v-row 
            align="center"
            justify="center"
            no-gutters
        >
          <v-col cols="10">
              <BaseMasterFieldBlock
                  title="Конструктор карт"
                  >
                  <template v-slot:input>
                      <CompanyCard 
                        :program="program"
                        @changeFields="changeFields($event)"
                        @validation="validate($event)"
                      />

                  </template>
            </BaseMasterFieldBlock>
          </v-col>
        </v-row>
        <v-row 
            class="mt-3"
            align="center"
            justify="center"
            no-gutters
          >
          <v-col cols="10">
            <v-btn
                color="primary"
                :disabled="!valid"
                :loading="loading"
                @click="updateCard()"
              >
                <v-icon style="margin-right: 10px;">
                  $iconify_ion-checkmark-circle-outline
                </v-icon>
                Сохранить
            </v-btn>
          </v-col>
        </v-row>
    </div>
</template>

<script>
import ApiService from '@/api/api-client'
import TopMenu from '@/components/base/TopMenu'
import Routing from '@/mixins/routing'
import CompanyCard from '../form_component/CompanyCard.vue'
export default {
    mixins: [Routing],
    data(){
        return {
            loading : false,    
            editCard : {

            },
            valid : false,
        }
    },
    components: {
      TopMenu,
      CompanyCard,
    },
    computed: {
      menu () {
        return this.$store.getters['company/program/menu']
      },
      program() {
        return this.$store.getters['company/program/programModel']
      },
    },
    methods:{
        async updateCard () {
          try {
            this.loading = true
            if (this.editCard.heroImageUri && this.editCard.mainImageUri && this.editCard.programLogoUri){
              const formData = new FormData();
              formData.append('data', JSON.stringify({
                codeType: this.editCard.codeType,
                hexBackgroundColor: this.editCard.hexBackgroundColor,
                hexLabelColor: this.editCard.hexLabelColor,
                hexFontColor: this.editCard.hexFontColor,
                merchantName: this.editCard.merchantName,
                merchantTel: this.editCard.merchantTel,
                merchantUrl: this.editCard.merchantUrl,
                cardHeader: this.editCard.programName,
                hideProgramName: this.editCard.hideProgramName,
                textBody: this.editCard.textBody,
                textHeader: this.editCard.textHeader,
                heroImageUri: typeof this.editCard.heroImageUri === 'string' ? this.editCard.heroImageUri : null,
                mainImageUri: typeof this.editCard.mainImageUri === 'string' ? this.editCard.mainImageUri : null,
                programLogoUri: typeof this.editCard.programLogoUri === 'string' ? this.editCard.programLogoUri : null,
              }))

              if (typeof this.editCard.heroImageUri !== 'string')
                formData.append('heroImageUri', this.editCard.heroImageUri, 'heroImageUri')

              if (typeof this.editCard.mainImageUri !== 'string')
                formData.append('mainImageUri', this.editCard.mainImageUri, 'mainImageUri')

              if (typeof this.editCard.programLogoUri !== 'string')
                formData.append('programLogoUri', this.editCard.programLogoUri, 'programLogoUri')

              formData.append('programId', this.program.id)

              try{
                  const result = await ApiService.post('api-cabinet/mobile-wallet/save-template', formData)

                  console.log('SET_CARD', result);
                  this.$notify({
                    title: 'Дизайн карты',
                    text: 'Дизайн карты обновлен!',
                    type: 'success',
                  })
                  //this.$store.dispatch('company/program/resetCardConstructor')
                  //this.toRoute('/dashboard')
              }
              catch(e){
                this.$notify({
                    title: 'Дизайн карты',
                    text: 'Произошла ошибка',
                    type: 'error',
                  })
              }
              
              
            }
            else{
              this.$notify({
                title: 'Дизайн карты',
                text: 'Необходимо загрузить все изображения!',
                type: 'error',
              })
            }
          } 
          finally {
            this.loading = false
          }
      },
      changeFields(event){
        //console.log('JSON',event)
        this.editCard = event
      },
      saveToJson(){
        const a = document.createElement("a")
        a.href = URL.createObjectURL(new Blob([JSON.stringify(this.editCard)], {
          type: "text/plain"
        }));
        a.setAttribute("download", "data_" + Date.now() + ".json");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      validate(e){
        this.valid = e.valid
      },
      cancel(){
        this.$store.dispatch('company/program/resetCardConstructor')
        this.toRoute('/dashboard')
      }

    },
    destroyed(){
      this.$store.dispatch('company/program/resetCardConstructor')
    }
}
</script>

<style scoped>

</style>