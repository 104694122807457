<template>
    <div>
        <v-row >
            <v-col>
                <v-btn min-width="300px"  @click="toogleColorPicker" class="d-flex justify-space-between">
                    <div><slot> </slot> </div>
                    <div class="color-circle" :style="'background-color: ' + currentColor + ';'"></div>
                </v-btn>
            </v-col>
        </v-row>
        <v-color-picker 
            v-click-outside="toogleColorPicker" 
            class="color-picker" 
            v-if="colorPickerVisible" 
            v-model="currentColor"
            @update:color="$emit('input', currentColor)"
        >
        </v-color-picker>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
    directives: {
      clickOutside: vClickOutside.directive,
    },
    props:{
        value : {type :String, require: true},
        small : {type : Boolean, default : false}
    },
    data(){
        return {
            currentColor : "#FFFFFF",
            colorPickerVisible: false,
        }
    },
    methods:{
        toogleColorPicker(){
            this.colorPickerVisible = !this.colorPickerVisible
        }
    },
    created(){
        this.currentColor = this.value
    }
}
</script>

<style scoped>
.color-picker{
    position: absolute;
    z-index: 2;

}

.color-circle{
    width: 25px;
    height: 25px;
    border-radius: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
</style>