<template>
    <v-row>   
        <v-col>
            <v-tabs v-model="settingsTab">
                <v-tab>
                    Поля
                </v-tab>
                <v-tab>
                    Цвета
                </v-tab>
                <v-tab>
                    Изображения
                </v-tab>
            </v-tabs>
            <div v-if="settingsTab === 0">
                <keep-alive>
                    <fields-component
                        @updateFields="updateFields($event, 'fields')"
                        :data="fields"
                        :loading="loading"
                        :walletType="walletType"
                        v-on="$listeners"
                        
                    />
                </keep-alive>
            </div>
            <div v-if="settingsTab === 1">
                <keep-alive>

                    <fields-color-component
                        class="mt-1"
                        :defaultBackground="fields.hexBackgroundColor"
                        :defaultForeground="fields.hexFontColor"
                        :defaultLabelColor="fields.hexLabelColor"
                        :walletType="walletType"
                        @updateColors="updateFields($event, 'colors')"
                    />
                </keep-alive>

            </div>
            <div v-if="settingsTab === 2">
                <keep-alive>

                    <fields-image-component
                        class="mt-1"
                        :defaultImages="[fields.programLogoUri, fields.mainImageUri, fields.heroImageUri ]"
                        @updateImages="updateFields($event, 'images')"
                    >

                    </fields-image-component>
                </keep-alive>

            </div>
        </v-col>
        <card-switcher
            class="d-flex align-center flex-column justfy-start"
            :fields="fields"
            :images="innerImages"
            @change="changeWalletType($event)"
        >

        </card-switcher>
    </v-row>

</template>

<script>
import colorPicker from "../form_component/components/colorPicker.vue"
import cardSwitcher from '../form_component/components/cardSwitcher.vue'
import fieldsComponent from  "../form_component/components/fieldsComponent.vue"
import fieldsColorComponent from "./components/fieldsColorComponent.vue"
import fieldsImageComponent from "./components/fieldsImageComponent.vue"
export default {
    components:{
        colorPicker, cardSwitcher, fieldsComponent, fieldsColorComponent, fieldsImageComponent
    },
    props:{
        program : Object
    },
    data(){
        return {
            loading : false,
            colorPicker : false,
            walletType : 0,
            settingsTab : 0,
            fields : {},
            innerImages : [],
        }
    },
    computed:{
        serverFields(){
            return this.$store.getters['company/program/cardFields']
        },
        merchant(){
            return this.$store.getters['settings/organization/merchant']
        },
    },
    watch:{
        fields :{
            deep: true,
            handler(v){
                //console.log('COMPANYCARD CHANGE FIELDS', v);
                this.$emit('changeFields', v)
            }
        },
        additionalFields:{
            handler(v){
                if (this.walletType === 0){
                    console.log("Google Fields Change", v)
                }
                else{
                    console.log("Apple Fields Change", v)
                }
            },
            deep: true
        },
    },
    async created(){
        this.loading = true
        await this.$store.dispatch('settings/organization/details')
        await this.$store.dispatch('company/program/getCardData', this.program.id)
        if (this.serverFields)
            this.fields = {
                textHeader : this.serverFields.textHeader,
                textBody : this.serverFields.textBody,
                codeType : this.serverFields.codeType ? this.serverFields.codeType : "CODE_128",
                merchantUrl : this.serverFields.merchantUrl ? this.serverFields.merchantUrl : "",
                merchantTel : this.serverFields.merchantTel ?  this.serverFields.merchantTel : this.merchant.details && this.merchant.details.phone ? this.merchant.details.phone : "",
                programName : this.serverFields.cardHeader ? this.serverFields.cardHeader : this.program.name,
                merchantName : this.serverFields.merchantName ?  this.serverFields.merchantName : this.merchant.name ? this.merchant.name : "",
                hideProgramName : this.serverFields.hideProgramName,
                hexBackgroundColor : this.serverFields.hexBackgroundColor ? this.serverFields.hexBackgroundColor : this.program.bgcolor1,
                hexLabelColor: this.serverFields.hexLabelColor ? this.serverFields.hexLabelColor : "#FFFFFF",
                hexFontColor: this.serverFields.hexFontColor ? this.serverFields.hexFontColor : "#000000",
                programLogoUri: this.serverFields.programLogoUri ? this.serverFields.programLogoUri : "",
                heroImageUri: this.serverFields.heroImageUri ?  this.serverFields.heroImageUri : "",
                mainImageUri: this.serverFields.mainImageUri ? this.serverFields.mainImageUri : ""
            }
        this.innerImages.push(this.fields.programLogoUri, this.fields.heroImageUri, this.fields.mainImageUri)
        this.loading = false
    },
    methods:{
        changeWalletType(e){
            this.walletType = e
        },
        updateFields(event, caller){
            if (caller === 'fields'){
                this.fields.textHeader = event.textHeader
                this.fields.textBody = event.textBody
                this.fields.codeType = event.barcode
                this.fields.merchantUrl = event.merchantUrl
                this.fields.merchantTel = event.merchantTel ? event.merchantTel.replace(/[^0-9]/g,"") : ""
                this.fields.programName = event.programName
                this.fields.merchantName = event.merchantName
                this.fields.hideProgramName = event.hideProgramName
            }
            if (caller === 'colors'){
                this.fields.hexBackgroundColor = event.backgroundColor
                this.fields.hexLabelColor = event.labelColor
                this.fields.hexFontColor = event.foregroundColor
            }
            if (caller === 'images'){
                this.fields.heroImageUri = event[0].push
                this.fields.mainImageUri = event[0].hero
                this.fields.programLogoUri = event[0].logo
                this.innerImages = []
                this.innerImages.push(event[1].logo, event[1].push, event[1].hero)
            }
            this.$emit('changeFields', this.fields)
        }
    }
}
</script>

<style scoped>

.section{
    color: #2A2A34;
    font-size: 20px;
    margin-bottom: 16px;
}

.v-input--selection-controls{
    padding-top: 0px;
}
.add-button{
    margin: 0 0 !important;
    width: 30px
}

.additionalfield-select{
    width: 200px;
}

.additionalfield-position-select{
    width: 100px;
}

</style>