<template>
  <div class="">
    <v-form
        ref="form"
        v-model="valid"
    >
        <v-row>
            <v-col cols="12">
                <base-text-field
                    v-model="fields.programName"
                    counter
                    class="mb-1"
                    placeholder="Заголовок на карте"
                    outlined
                    hide-details="auto"
                    style="margin-top: 2rem !important"
                    :rules="[rule_required]"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col v-if="false" cols="12">
                <base-text-field
                    v-model="fields.merchantName"
                    class="mb-1"
                    placeholder="Эмитент"
                    outlined
                    hide-details="auto"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="fields.merchantUrl"
                    class="mb-1"
                    placeholder="Сайт компании"
                    outlined
                    hide-details="auto"
                    :error-count="1"
                    :rules="[rule_adress]"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col cols="6">
                <vue-tel-input-vuetify
                    v-model="fields.merchantTel"
                    class="mb-1"
                    placeholder="Номер телефона"
                    outlined
                    :rules="[rule_required]"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col cols="12" style="margin-top: -2rem;">
                <base-text-field
                    v-model="fields.textHeader"
                    class="mb-1"
                    placeholder="Заголовок на обратной стороне"
                    outlined
                    hide-details="auto"
                    :rules="[rule_required]"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col cols="12">
                <base-text-field
                    v-model="fields.textBody"
                    class="mb-1"
                    placeholder="Текст на обратной стороне"  
                    outlined
                    hide-details="auto"
                    :rules="[rule_required]"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col cols="12">
                <v-autocomplete
                    :items="barcodeTypes"
                    v-model="fields.barcode"
                    label="Тип штрих-кода"
                    outlined
                    hide-details="auto"
                    class="mb-1"
                    :loading="loading || internalLoading"
                />
            </v-col>
            <v-col v-show="walletType === 1" cols="12">
                <base-ext-switch
                    label="Скрывать название программы"
                    v-model="fields.hideProgramName"
                    :loading="loading || internalLoading"
                />
            </v-col>
        </v-row>
    </v-form>
    
  </div>
</template>

<script>
export default {
    props:{
        data: {type: Object, default: null},
        loading : Boolean,
        walletType: {type: Number, default: 0}
    },
    data() {
        return {
            valid : false,
            internalLoading : false,
            fields : {
                textHeader : "",
                textBody : "",
                barcode : 'CODE_128',
                merchantUrl : '',
                merchantTel : '',
                programName : '',
                merchantName: '',
                hideProgramName : false,
            },
            barcodeTypes : ['QR_CODE', 'CODE_128'],
            rule_required : v => !!v || 'Поле обязательно для заполнения',
            rule_adress : v => {
                if (v.length === 0)
                    return true
                return /\w{2,256}\.\w{2,10}/gi.test(v) || 'Введен некорректный адрес'
            }
        }
    },
    computed:{
        program(){
            return this.$store.getters['company/program/programModel']
        },
        merchant(){
            return this.$store.getters['settings/organization/merchant']
        }
    },
    watch:{
        fields : {
            deep : true,
            handler(v){
                //this.$refs.form.validate()
                this.$emit('updateFields', this.fields)
                //console.log('change fields', v);
            }
        },
        valid(v){
            this.$emit('validation', {component : 'field', valid : v})
        },
        data:{
            deep: true,
            handler(v){
                this.fields.textHeader = this.data.textHeader
                this.fields.textBody = this.data.textBody
                this.fields.barcode = this.data.codeType
                this.fields.merchantUrl = this.data.merchantUrl
                this.fields.merchantTel = this.data.merchantTel
                this.fields.programName = this.data.programName
                this.fields.merchantName= this.data.merchantName
                this.fields.hideProgramName = this.data.hideProgramName
            }
        }
    },
    async mounted(){
        if (!this.checkDataEmpty()){
            this.internalLoading = true
            await this.$store.dispatch('settings/organization/details')
            this.internalLoading = false
        }
        this.init()
        console.log('MERCHANT', this.merchant);
    },
    methods:{
        init(){
            console.log('FIELDS INIT DATA', this.data);
            if (!this.checkDataEmpty()){
                this.fields.merchantName = this.merchant.name ? this.merchant.name : ""
                this.fields.merchantTel = this.merchant.details && this.merchant.details.phone ? this.merchant.details.phone : ""
                this.fields.programName = this.program.name ? this.program.name : ""
            }
            else{
                this.fields.textHeader = this.data.textHeader
                this.fields.textBody = this.data.textBody
                this.fields.barcode = this.data.codeType
                this.fields.merchantUrl = this.data.merchantUrl
                this.fields.merchantTel = this.data.merchantTel
                this.fields.programName = this.data.programName
                this.fields.merchantName= this.data.merchantName
                this.fields.hideProgramName = this.data.hideProgramName
            }
        },
        checkDataEmpty(){
            return this.data.textHeader && this.data.textBody && this.data.merchantTel && this.data.programName && this.data.merchantName
        }
    }
}
</script>

<style scoped>

.v-select__slot{
    min-height: 60px;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input { max-height: 25px !important; }
</style>